import * as React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { selectAuthUserData, selectIsLoggedIn } from 'app/store/auth/selects';

import { AUTH_ROUTES, VIDEO_ROUTES } from 'shared/config/routes';

export const withEmailVerification = (Component) => {
	// eslint-disable-next-line react/display-name
	return (props: React.PropsWithChildren) => {
		Component.displayName = 'WrappedByWithEmailVerification';

		const location = useLocation();
		const isUserAuth = selectIsLoggedIn();
		const authUserData = selectAuthUserData();
		const isMustVerifyEmailPage =
			location.pathname === AUTH_ROUTES.MUST_VERIFY_EMAIL.path;
		const isVerifyingEmailPage =
			location.pathname === AUTH_ROUTES.VERIFY_EMAIL.path;
		const isEmailPage = location.pathname.startsWith(
			AUTH_ROUTES.EMAIL_ROOT.path,
		);

		if (
			isUserAuth &&
			!authUserData!.isEmailConfirmed &&
			isMustVerifyEmailPage
		) {
			return props.children;
		}

		if (isUserAuth && !authUserData!.isEmailConfirmed && isVerifyingEmailPage) {
			return props.children;
		}

		if (isUserAuth && authUserData!.isEmailConfirmed && isEmailPage) {
			return <Navigate to={VIDEO_ROUTES.MY_VIDEOS.path} replace />;
		}

		if (isUserAuth && !authUserData!.isEmailConfirmed && !isEmailPage) {
			return (
				<Navigate
					to={
						location.state?.from?.pathname?.startsWith(
							AUTH_ROUTES.EMAIL_ROOT.path,
						)
							? location.state.from
							: AUTH_ROUTES.MUST_VERIFY_EMAIL.path
					}
					replace
				/>
			);
		}

		return <Component {...props} />;
	};
};
