export enum VIDEO_MODALS_NAMES {
	delete_video = 'delete_video',
	delete_comment = 'delete_comment',
	access_setting_video = 'access_setting_video',
	edit_profile_user = 'edit_profile_user',
}

export enum VideoPermissionsEnum {
	ONLY_ME = 'ONLY ME',
	AUTHENTICATE_USER = 'AUTHENTICATE USER',
	ANYONE_WITH_LINK = 'ANYONE WITH LINK',
}
