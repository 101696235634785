import * as React from 'react';

import {
	Typography as TypographyExternal,
	TypographyProps as TypographyExternalProps,
} from '@mui/material';

interface TypographyProps {
	variant: TypographyExternalProps['variant'];
	className?: string;
	component?: React.ElementType;
	onClick?: (e: React.MouseEvent<HTMLHtmlElement>) => void;
}

export const Typography: React.FC<React.PropsWithChildren<TypographyProps>> = ({
	className,
	variant,
	component = 'p',
	children,
	onClick,
}) => {
	return (
		<TypographyExternal
			variant={variant}
			component={component}
			className={className}
			onClick={onClick}
		>
			{children}
		</TypographyExternal>
	);
};
